import { Palette, svgIconClasses } from '@mui/material';
import '@mui/material/Button';
import '@mui/material/IconButton';
import '@mui/material/SvgIcon';
import { Theme, ThemeOptions, createTheme } from '@mui/material/styles';

import {
  ANSARADA_REGULAR_FONTS,
  ANSARADA_VARIABLE_FONTS,
  generateAnsaradaFavoritFontFace,
  supportVariableFont,
} from '../fonts';
import { ThemeMode } from '../types';
import { createPalette } from './color-palette';

declare module '@mui/material/styles' {
  interface ThemeOptions {
    name: string;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true;
    secondary: true;
    caution: true;
    ghost: true;
    chaos: true;
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    primary: true;
    secondary: true;
    caution: true;
    ghost: true;
    chaos: true;
  }
}

declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsSizeOverrides {
    xlarge: true;
    xxlarge: true;

    default: true;
  }
}

export function createWebsiteTheme({ name, mode }: { name: string; mode: ThemeMode }): Theme {
  const palette = createPalette(mode) as Palette;
  const themeOptions: ThemeOptions = {
    name,
    palette,
    breakpoints: {
      values: {
        xs: 0,
        sm: 431,
        md: 768,
        lg: 1280,
        xl: 1400,
      },
    },
    spacing: [2, 4, 8, 12, 16, 24, 32, 64, 80, 128, 140],
    shape: {
      borderRadius: 8,
    },
    shadows: [
      'none',
      '0px 1px 2px rgba(0, 0, 0, 0.2), 0px 1px 3px rgba(0, 0, 0, 0.1)',
      '0px 3px 6px rgba(0, 0, 0, 0.23), 0px 3px 6px rgba(0, 0, 0, 0.16)',
      '0px 6px 6px rgba(0, 0, 0, 0.26), 0px 10px 20px rgba(0, 0, 0, 0.19)',
      '0px 10px 10px rgba(0, 0, 0, 0.26), 0px 14px 28px rgba(0, 0, 0, 0.25)',
      '0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
      'none',
    ],
    components: {
      MuiCssBaseline: {
        styleOverrides: `
            ${generateAnsaradaFavoritFontFace('variable')}

            @supports (font-variation-settings: normal) {
              *:not(.ans-no-font *) {
                font-family: ${ANSARADA_VARIABLE_FONTS.join(',')} !important;
              }
            }
          `,
      },

      MuiSvgIcon: {
        defaultProps: {
          fontSize: 'default',
        },
        styleOverrides: {
          root: {
            color: palette._grey._600,
          },
        },
        variants: [
          {
            props: { fontSize: 'default' },
            style: {
              fontSize: '1.5rem',
            },
          },
          {
            props: { fontSize: 'small' },
            style: {
              fontSize: '13px',
            },
          },
          {
            props: { fontSize: 'medium' },
            style: {
              fontSize: '20px',
            },
          },
          {
            props: { fontSize: 'large' },
            style: {
              fontSize: '24px',
            },
          },
          {
            props: { fontSize: 'xlarge' },
            style: {
              fontSize: '32px',
            },
          },
          {
            props: { fontSize: 'xxlarge' },
            style: {
              fontSize: '60px',
            },
          },
        ],
      },

      MuiIconButton: {
        defaultProps: {
          disableRipple: true,
          color: 'ghost',
        },
        styleOverrides: {
          root: {
            padding: '8px',
            borderRadius: '8px',

            [`.${svgIconClasses.root}`]: {
              fontSize: '20px',
            },
          },
        },
        variants: [
          {
            props: { color: 'primary' },
            style: {
              backgroundColor: palette._grey._700,

              [`.${svgIconClasses.root}`]: {
                color: palette.order,
              },

              '&:hover, &:focus, &:focus-visible': {
                backgroundColor: palette.chaos,
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._700}`,
              },

              '&:active': {
                boxShadow: `inset 0px 0px 0px 0px ${palette._grey._600}`,
                backgroundColor: palette.chaos,
              },

              '&:disabled': {
                opacity: 1,
                backgroundColor: palette._grey._300,

                [`.${svgIconClasses.root}`]: {
                  color: palette.order,
                },
              },
            },
          },
          {
            props: { color: 'chaos' },
            style: {
              backgroundColor: palette.citrus._400,

              [`.${svgIconClasses.root}`]: {
                color: palette.chaos,
              },

              '&:hover, &:focus, &:focus-visible': {
                boxShadow: `inset 0px 0px 0px 1px ${palette.citrus._700}`,
                backgroundColor: palette.citrus._300,
              },

              '&:active': {
                backgroundColor: palette.citrus._300,
                boxShadow: `inset 0px 0px 0px 0px ${palette.citrus._600}`,
              },

              '&:disabled': {
                opacity: 1,
                backgroundColor: palette.citrus._100,

                [`.${svgIconClasses.root}`]: {
                  color: palette.order,
                },
              },
            },
          },
          {
            props: { color: 'caution' },
            style: {
              backgroundColor: palette.neon._500,

              [`.${svgIconClasses.root}`]: {
                color: palette.order,
              },

              '&:hover, &:focus, &:focus-visible': {
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._700}`,
                backgroundColor: palette.neon._600,
              },

              '&:active': {
                backgroundColor: palette.neon._600,
                boxShadow: `inset 0px 0px 0px 0px ${palette._grey._600}`,
              },

              '&:disabled': {
                opacity: 1,
                backgroundColor: palette.neon._200,

                [`.${svgIconClasses.root}`]: {
                  color: palette.order,
                },
              },
            },
          },
          {
            props: { color: 'secondary' },
            style: {
              backgroundColor: palette.order,
              boxShadow: `inset 0px 0px 0px 1px ${palette._grey._600}`,
              [`.${svgIconClasses.root}`]: {
                color: palette._grey._700,
              },

              '&:hover, &:focus, &:focus-visible': {
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._600}`,
                [`.${svgIconClasses.root}`]: {
                  color: palette.chaos,
                },
              },

              '&:active': {
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._700}`,

                [`.${svgIconClasses.root}`]: {
                  color: palette.chaos,
                },
              },

              '&:disabled': {
                opacity: 1,
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._300}`,
                backgroundColor: palette.order,

                [`.${svgIconClasses.root}`]: {
                  color: palette._grey._400,
                },
              },
            },
          },
          {
            props: { color: 'ghost' },
            style: {
              backgroundColor: palette.order,

              '&:hover, &:focus, &:focus-visible': {
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._600}`,
              },

              '&:active': {
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._700}`,

                [`.${svgIconClasses.root}`]: {
                  color: palette.chaos,
                },
              },

              '&:disabled': {
                opacity: 1,

                [`.${svgIconClasses.root}`]: {
                  color: palette._grey._400,
                },
              },
            },
          },
        ],
      },

      MuiLink: {
        defaultProps: {
          underline: 'always',
        },
        styleOverrides: {
          root: {
            textDecorationColor: 'unset',
            color: palette._text.base,

            ':hover': {
              color: palette._text.dark,
            },
          },
        },
      },

      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },

      MuiButton: {
        defaultProps: {
          variant: 'primary',
        },
        variants: [
          {
            props: { variant: 'primary' },
            style: {
              borderRadius: '8px',
              backgroundColor: palette._grey._700,
              color: palette.order,
              boxShadow: 'none',

              [`.${svgIconClasses.root}`]: {
                color: palette.order,
              },

              '&:hover, &:focus, &:focus-visible': {
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._700}`,
                backgroundColor: palette.chaos,
              },

              '&:active': {
                boxShadow: `inset 0px 0px 0px 0px ${palette._grey._700}`,
              },

              '&:disabled': {
                backgroundColor: palette._grey._300,
                boxShadow: `inset 0px 0px 0px 0px ${palette._grey._700}`,
                color: palette.order,
              },
            },
          },
          {
            props: { variant: 'secondary' },
            style: {
              backgroundColor: palette.order,
              borderRadius: '8px',
              boxShadow: `inset 0 0 0 1px ${palette._grey._600}`,
              color: palette._grey._700,
              border: 'none !important',

              '&:hover, &:focus, &:focus-visible': {
                color: palette.chaos,
                backgroundColor: palette.order,
                boxShadow: `inset 0 0 0 1px ${palette._grey._600}`,
              },

              '&:active': {
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._700}`,
              },

              '&:disabled': {
                backgroundColor: palette.order,
                color: palette._grey._400,
                boxShadow: `inset 0 0 0 1px ${palette._grey._300}`,
              },
            },
          },
          {
            props: { variant: 'chaos' },
            style: {
              borderRadius: '8px',
              backgroundColor: palette.citrus._400,
              color: palette.chaos,
              boxShadow: 'none',

              [`.${svgIconClasses.root}`]: {
                color: palette.chaos,
              },

              '&:hover, &:focus, &:focus-visible': {
                boxShadow: `inset 0px 0px 0px 1px ${palette.citrus._700}`,
                backgroundColor: palette.citrus._300,
              },

              '&:active': {
                boxShadow: `inset 0px 0px 0px 0px ${palette.citrus._700}`,
              },

              '&:disabled': {
                backgroundColor: palette.citrus._100,
                boxShadow: `inset 0px 0px 0px 0px ${palette._grey._700}`,
                color: palette._grey._500,
              },
            },
          },
          {
            props: { variant: 'ghost' },
            style: {
              color: palette._grey._700,
              backgroundColor: palette.order,
              borderRadius: '8px',
              boxShadow: `inset 0 0 0 0px ${palette._grey._600}`,
              textDecoration: 'underline !important',

              '&:hover, &:focus, &:focus-visible': {
                color: palette.chaos,
                backgroundColor: palette.order,
                boxShadow: `inset 0 0 0 1px ${palette._grey._600}`,
              },

              '&:active': {
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._700}`,
              },

              '&:disabled': {
                backgroundColor: `${palette.order}`,
                color: palette._grey._400,
              },
            },
          },
          {
            props: { variant: 'caution' },
            style: {
              color: palette.order,
              backgroundColor: palette.neon._500,
              borderRadius: '8px',

              [`.${svgIconClasses.root}`]: {
                color: palette.order,
              },

              '&:hover, &:focus, &:focus-visible': {
                boxShadow: `inset 0px 0px 0px 1px ${palette._grey._700}`,
                backgroundColor: palette.neon._600,
              },

              '&:active': {
                boxShadow: `inset 0px 0px 0px 0px ${palette._grey._600}`,
              },

              '&:disabled': {
                backgroundColor: palette.neon._200,
                color: palette.order,
              },
            },
          },
        ],
      },

      MuiList: {
        styleOverrides: {
          root: {
            paddingTop: '0px',
            paddingBottom: '0px',
          },
        },
      },

      MuiSkeleton: {
        variants: [
          {
            props: { variant: 'rounded' },
            style: {
              borderRadius: '4px',
            },
          },
          {
            props: { variant: 'rectangular' },
            style: {
              borderRadius: '0px',
            },
          },
          {
            props: { variant: 'text' },
            style: {
              borderRadius: '0px',
            },
          },
        ],
      },
    },

    typography: {
      fontFamily: ANSARADA_REGULAR_FONTS.join(','),
      h1: {
        fontSize: '80px',
        lineHeight: '84px',
        letterSpacing: '-3.2px',
        fontWeight: 300,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 300" }),
      },
      h2: {
        fontSize: '40px',
        lineHeight: '44px',
        letterSpacing: '-1.6px',
        fontWeight: 300,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 300" }),
      },
      h3: {
        fontSize: '36px',
        lineHeight: '40px',
        letterSpacing: '-1.44px',
        fontWeight: 350,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 350" }),
      },
      h4: {
        fontSize: '32px',
        lineHeight: '36px',
        letterSpacing: '-1.28px',
        fontWeight: 350,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 350" }),
      },
      h5: {
        fontSize: '28px',
        lineHeight: '32px',
        letterSpacing: '-1.12px',
        fontWeight: 400,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 400" }),
      },
      h6: {
        fontSize: '24px',
        lineHeight: '28px',
        letterSpacing: '-0.96px',
        fontWeight: 400,
        ...supportVariableFont('regular', { fontVariationSettings: "'wdth' 400" }),
      },
      subtitle1: {
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '-0.8px',
        fontWeight: 500,
        ...supportVariableFont('bold', { fontVariationSettings: "'wdth' 500" }),
      },
      subtitle2: {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.64px',
        fontWeight: 500,
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 500",
        }),
      },
      body1: {
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '-0.32px',
        fontWeight: 350,
        ...supportVariableFont('bold', {
          fontVariationSettings: "'wdth' 350",
        }),
      },
      body2: {
        fontSize: '14px',
        lineHeight: '16px',
        letterSpacing: '-0.28px',
        fontWeight: 350,
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 350",
        }),
      },
      caption: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '-0.24px',
        fontWeight: 350,
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 350",
        }),
      },
      overline: {
        fontSize: '12px',
        lineHeight: '16px',
        letterSpacing: '-0.24px',
        fontWeight: 350,
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 350",
        }),
      },
      button: {
        fontSize: '16px',
        lineHeight: '20px',
        fontWeight: 350,
        textTransform: 'none',
        ...supportVariableFont('regular', {
          fontVariationSettings: "'wdth' 350",
        }),
      },
    },
  };
  return createTheme(themeOptions);
}
